import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { Grid, Hidden } from '@material-ui/core'
import './_header.scss'
import { useHistory } from 'react-router-dom'
import { delay } from "lodash";

import document from "../../assets/svg/notification/doc.svg";
import message from "../../assets/svg/notification/message.svg";
import ProfileView from './profile_view/profile_view'
import DashboardSidebar from '../dashboard/dashboard_sidebar/dashboard_sidebar';

function NotificationDropdown( props ) {
  const [ notificationList, setNotificationList ] = useState([])
  const [ showNotification, setShowNotification ] = useState(false)
  const [ notificationMouseState, setNotificationMouseState ] = useState(false)
  const [ realtime, setRealtime ] = useState(false)

  const { cleanDateTimeString, history } = props;

  useEffect(() => {
    getNotifications()
    delay(() => {
      setRealtime(!realtime)
    }, 3000)
  }, [realtime])

  function getNotifications() {
    const token = localStorage.getItem('authToken')
    axios.post('https://backend.production.bip.agiprod.agifly.cloud/dashboard/unread_notification', {}, {
        headers: {
          authorization: `Bearer ${token}`
        }
    }).then(response => {
        setNotificationList(response.data)
    }).catch(error => {
        console.log(error)
    })
  }

  const handleNotificationModal = () => {
    setShowNotification(!showNotification)
  }

  function goToDocument(document) {
    history.push(`/documenten&=${document}`)
  }

  function goToMessages(message) {
    history.push(`/berichten&=${message}`)
  }

  return(
    <div className = "header-bell-container relative cursor-pointer relative horizontal-center margin-right-30 outline-none" tabIndex = "1" onBlur = {() => { if(!notificationMouseState) setShowNotification(false) }}>
      <button type = "button" className = "height-auto width-auto cursor-pointer" onClick = {handleNotificationModal}>
          <img src = {require('../../assets/svg/icon/bell.svg')} alt = "bell" className = "header-bell object-fit-contain" />
          <div className = "header-bell-bubble center-content">
              <h1 className = "main-font-bold font-size-9 font-color-white">{notificationList.length > 0 ? notificationList.length : 0}</h1>
          </div>
      </button>
      <div className = "header-notification-container animatable-400 ease-in-out-quart outline-none" onMouseOver = {() => { setNotificationMouseState(true); }} onMouseLeave = {() => { setNotificationMouseState(false)}} style = {{height: showNotification ? `${notificationList.length > 6 ? 105 * 6 : 105 * notificationList.length}px` : '0px', opacity: showNotification ? 1 : 0}}>
          <div className = "header-notification">
              {notificationList.length > 0 && notificationList.map(item => (
                  <div className = "header-notification-item-border outline-none" onClick = {() => { item.type === "document" ? goToDocument(item.referenceId) : goToMessages(item.referenceId) }}>
                      <div className = "flex-column width-auto margin-top-15">
                          <div className = "flex-row horizontal-end vertical-center margin-right-27">
                              <img src = {item.type === "document" ? document : message} alt = "bell" className = "header-notification-icon" />
                              <p className = "main-font-semi-bold font-size-14 font-color-black line-height-20-p">{item.type === "document" ? 'Nieuw document' : 'Nieuw bericht'}</p>
                              <div className = "header-notification-bubble" />
                          </div>
                          <div className = "flex-row horizontal-end margin-right-40">
                              <p className = "main-font-regular font-size-14 font-color-black line-height-20-p text-align-end">{item.buildingName}</p>
                          </div>
                          <div className = "flex-row horizontal-end margin-right-40">
                              <p className = "main-font-regular font-size-14 font-color-black line-height-20-p text-align-end">{item.serviceName}</p>
                          </div>
                      </div>
                      <div className = "flex-row  horizontal-end margin-right-40 margin-top-7 margin-bottom-15">
                          <p className = "main-font-regular font-size-9 font-color-dark-grey">{cleanDateTimeString(item.date)}</p>
                      </div>
                  </div>
              ))}
          </div>
      </div>
    </div>
  )
}

export default function Header(props) {
  const [ userDetails, setUserDetails ] = useState(0)
  const [isProfileClick, setProfileClickState] = useState(false)

  const { hasButton, toShowHamburger, hideLogo } = props
  const history = useHistory()
  const [ showMenuState, setShowMenuState ] = useState(false)
  const dashboardRef = useRef(0)

  useEffect(() => {
    const userData = JSON.parse( localStorage.getItem("loggedInUserDetails"));
    setUserDetails(userData)
  }, []);

  const handleRoute = (isBilling) => {
    history.push(`/project-application/${isBilling}`)
  }

  const handleSignoutClick=()=>{
    localStorage.clear()
    history.push('/')
  }

  function cleanDateTimeString(date) {
    let newDate = date.split(" ")[0].split("-");
    let newTime = date.split(" ")[1].slice(0, -3)
    return `${newDate[2]}/${newDate[1]}/${newDate[0]} - ${newTime}`
  }

  const handleToggleSidebar = () => {
    dashboardRef.current.focus()
    setShowMenuState(!showMenuState)
  }


  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      // justify="center"
      
    >
      {toShowHamburger && (
        <div className = "residence-sidebar-style fixed animatable-400 ease-in-out-quart" tabIndex = "1" ref = {dashboardRef} onBlur = {() => { setShowMenuState(false) }} style = {{transform: showMenuState ? 'translateX(0px)' : 'translateX(-260px)', opacity: showMenuState ? 1 : 0}} >
        <DashboardSidebar modular  />
        </div>      
      )}
      
      <Grid container item md={12} justify="space-between" alignItems="center" className='header-style' style = {{zIndex: 9}}>
        <Grid container md={1} sm={1} xs={2} justify='center' className="mobile-icon">
          {toShowHamburger!==undefined && toShowHamburger && (
            <div className='center-content margin-left-5 margin-right-10'>
              <img
              alt = 'menu'
              className="epb-service-menu-icon"
              src={require('../../assets/svg/icon/menu.svg')}
              onClick={handleToggleSidebar}
              />
            </div>
          )}
        </Grid>
        <Grid container md={6} sm={4} xs={1} justify='space-between' className='cursor-pointer left-container'>
            {!hideLogo && (  window.screen.width > 600 ?
              (
              <Grid container md={4}  justify='flex-start' alignItems='center' className="logoMobile" onClick = {() => { history.push("/dashboard") }} >
                <img
                className="header-image object-fit-contain"
                alt="header"
                src={require('../../7b.png')}
                />
                <h1 className="main-font-bold font-size-22 font-color-navy-blue opacity-70 margin-left-10">
                    Docu
                </h1>
                <p className="main-font-light font-size-22 font-color-navy-blue">
                    Build
                </p>
              </Grid>
              ) : (
                <Grid container md={4}  justify='flex-start' alignItems='center' className="logoMobile" onClick = {() => { history.push("/dashboard") }} >
                  <img
                  className="header-image object-fit-contain"
                  alt="header"
                  src={require('../../7b.png')}
                  />
                  <h1 className="main-font-bold font-size-12 font-color-navy-blue opacity-70 margin-left-10">
                    Docu
                  </h1>
                  <p className="main-font-light font-size-12 font-color-navy-blue">
                      Build
                  </p>
                </Grid>
              )
            )}
          <Hidden mdUp>
            {handleToggleSidebar !== undefined && toShowHamburger===undefined && !toShowHamburger && (
              <div className='center-content margin-left-5 margin-right-10'>
                <img
                  alt = 'menu'
                  className="epb-service-menu-icon"
                  src={require('../../assets/svg/icon/menu.svg')}
                  onClick={handleToggleSidebar}
                />
              </div>
            )}
          </Hidden>
          {(props.hasButton || props.hasButton === undefined) && userDetails.roleId !== 6 && (
            <Hidden smDown>
              <Grid container md={8} sm={8} justify='flex-end' alignItems = 'center'>
                <Grid container md={6} sm={6}>
                  <button
                    type="button"
                    className="header-button-blue margin-left-10 width-auto background-color-sky-blue center-content cursor-pointer"
                    onClick={()=>{ handleRoute(true) }}
                  >
                    <span className="flex-row center-content">
                      <img src = {require('../../assets/svg/icon/add.svg')} alt = "add" className = "header-button-plus" />
                      <p className = "main-font-semi-bold font-color-white font-size-14 margin-left-10">Nieuwe Aanvraag</p>
                    </span>
                  </button>
                </Grid>
                <Grid container md={6} sm={6}>
                  <button
                    type="button"
                    className="header-button-white width-auto background-color-white flex center-content "
                    onClick={()=>{ handleRoute(false) }}
                  >
                    <span className="flex-row center-content">
                      <img src = {require('../../assets/png/icon/add_grey.png')} alt = "add" className = "header-button-plus" />
                      <p className = "main-font-semi-bold font-color-grey font-size-14 margin-left-10 cursor-pointer">Nieuwe offerte Aanvraag</p>
                    </span>
                  </button>
                </Grid>
              </Grid>
            </Hidden>
          )}
        </Grid>
        <Grid container md={6} sm={6} xs={9} justify='flex-end' alignItems='center' className="right-container">
          <NotificationDropdown cleanDateTimeString = {cleanDateTimeString} history = {history} />
          
          {userDetails.company !== undefined && userDetails.company !== null ?
            <img
              alt="profile"
              className="header-profile-image "
              src={ userDetails.company.profile_picture ? `https://backend.production.bip.agiprod.agifly.cloud/uploads/${userDetails.company.profile_picture}` : require('../../assets/svg/user.svg')}
              onClick={() => setProfileClickState(true)}
            />
            : <div className='header-profile-image background-color-grey' onClick={() => setProfileClickState(true)} />
            }
            <Hidden xsDown>
              <p className="vietnam-regular font-size-14 font-color-grey margin-left-10">
                {typeof userDetails === "object" && userDetails.firstName}<br></br>{typeof userDetails === "object" && userDetails.lastName}
              </p>
              <button
                type="button"
                className="header-button-blue background-color-sky-blue center-content margin-right-10 margin-left-10 cursor-pointer"
                onClick={handleSignoutClick}
              >
                <span className="flex-row center-content">
                  <p className = "main-font-semi-bold font-color-white font-size-14 margin-left-10 margin-right-10">Sign out</p>
                </span>
              </button>
            </Hidden>
            <Hidden smUp>
              <img alt = 'logout' src={require('../../assets/png/logout.png')} className='header-profile-image margin-left-10 margin-right-10' onClick={handleSignoutClick}/>
            </Hidden>
        </Grid>
      </Grid>
      <ProfileView
        modalState={isProfileClick}
        setModalState={setProfileClickState}
      />
      {(props.hasButton || props.hasButton === undefined) && (
      <Hidden mdUp>
        <Grid container justify='center' alignItems='center' className='header-style'>
            <Grid container xs={6} sm={6} md={5} justify='center'>
              <button
                type="button"
                className="header-button-blue background-color-sky-blue  center-content"
                onClick={()=>{handleRoute(true)}}
              >
                <span className="flex-row center-content margin-left-10 margin-right-10">
                  <img src = {require('../../assets/svg/icon/add.svg')} alt = "add" className = "header-button-plus" />
                  <p className = "main-font-semi-bold font-color-white font-size-14 margin-left-10">Nieuwe Aanvraag</p>
                </span>
              </button>
            </Grid>
            <Grid container xs={6} sm={6} md={5}>
              <button
                type="button"
                className="header-button-white background-color-white flex center-content "
                onClick={()=>{handleRoute(false)}}
              >
                <span className="flex-row center-content">
                  <img src = {require('../../assets/png/icon/add_grey.png')} alt = "add" className = "header-button-plus" />
                  <p className = "main-font-semi-bold font-color-grey font-size-14 margin-left-10">Nieuwe offerte Aanvraag</p>
                </span>
              </button>
          </Grid>
      </Grid>
      </Hidden>
      )}
    </Grid>
  )
}

Header.defaultProps = {
  hideLogo: false,
}