import React, { useState } from 'react'; 
import { Document, Page,pdfjs } from 'react-pdf'; 
import { Grid, Hidden } from '@material-ui/core'
import './_premium_info.scss'

export default function PremiumInfo (props) {
    const {handleChange, setCategoryId} = props
    
    const url =  
    "https://backend.production.bip.agiprod.agifly.cloud/premier/premier.pdf"
      

    pdfjs.GlobalWorkerOptions.workerSrc =  
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 
    const [numPages, setNumPages] = useState(null); 
    const [showLoading, setLoadingState] = useState(true)

    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => { 
      event.preventDefault(); 
    }); 
      
    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) { 
        setLoadingState(false)
        setNumPages(numPages); 
    }

    return (
        <Grid container justify = 'center' alignItems = 'center' >
            <div className="residence-line-between " />
            <Hidden xsDown>
                <div className = 'center-content width-90-p animatable-400 fade-in-translate overflow-y-auto' style = {{height: '80vh'}}>
                    <div className = "height-auto" >
                        <Document 
                            file={url} 
                            onLoadSuccess={onDocumentLoadSuccess}              
                        > 
                            {numPages !== null &&
                                Array(numPages).fill().map((page, pageNumber) => (
                                    <Page size = {{width: '100%'}} pageNumber = {pageNumber + 1} /> 
                            ))}
                        </Document> 
                    </div>
                    { showLoading && (
                        <div class="lds-roller margin-left-15">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        <span className = "height-auto width-auto center-content absolute">
                            <p className = "main-font-semi-bold font-size-12 font-color-blue">Loading</p>
                        </span>
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden smUp>
                <div className = 'center-content width-auto animatable-400 fade-in-translate overflow-y-auto' style = {{height: '80vh'}}>
                    <div className = 'width-100-p height-100-p'>
                        <Document 
                            file={url} 
                            onLoadSuccess={onDocumentLoadSuccess}              
                        > 
                            {numPages !== null &&
                                Array(numPages).fill().map((page, pageNumber) => (
                                    <Page size = {{width: '100%'}} pageNumber = {pageNumber + 1} /> 
                            ))}
                        </Document> 
                    </div>
                    { showLoading && (
                        <div class="lds-roller margin-left-15">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        <span className = "height-auto width-auto center-content absolute">
                            <p className = "main-font-semi-bold font-size-12 font-color-blue">Loading</p>
                        </span>
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden xsDown>
                <p className = 'link-style cursor-pointer width-auto vertical-end horizontal-end margin-right-20 ' onClick={() => {handleChange('emails'); setCategoryId(1)}}>Hulp nodig bij het zoeken naar premies?  Wij helpen u graag verder</p>
            </Hidden>
            <Hidden smUp>
                <p className = 'link-style cursor-pointer width-auto vertical-end horizontal-end margin-right-20 margin-top-15 ' onClick={() => {handleChange('emails'); setCategoryId(1)}}>Hulp nodig bij het zoeken naar premies?  Wij helpen u graag verder</p>
            </Hidden>
        </Grid>
    )
}
