import React, { useState, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Header from '../Header/header'
import axios from 'axios'
import ResidenceAmboise from './components/residence_amboise/residence_amboise'
import ResidenceAmboiseActions from './components/residence_amboise_actions/residence_amboise_actions'
import ActionsInformation from './components/actions_information/actions_information'
import InfoTab from './components/actions_information/info_tab/info_tab'
import EmailsTab from './components/actions_information/emails_tab/emails_tab'
import DocumentDetails from './components/actions_information/document_details/document_details'
import DashboardSidebar from '../dashboard/dashboard_sidebar/dashboard_sidebar';
import { isTokenValid } from "../shared/shared_functions";
import PremiumInfo from './components/premium_info/premium_info'

import './_residence.scss'

export default function Residence() {
  const [projectId, setProjectId] = useState()
  const [categoryId, setCategoryId] = useState(0)
  const [serviceId, setServiceId] = useState()
  const [projectServiceId, setProjectServiceId] = useState(0)
  const [buildingId, setBuildingId] = useState()
  const [update, setUpdate] = useState(false)
  const [serviceStatus,setServiceStatus]=useState(0)
  const [serviceFileNumber,setServiceFileNumber]=useState('-')
  const [responsibleList,setResponsibleList]=useState([])
  const [responsibleId,setResponsibleId]=useState(0)
  const [buildingDetails, setBuildingDetails] = useState({
    buildingName: '',
    buildingAddress: '',
    status:'',
    fileName:'',
    shift:'',
    typeOfProperty:'',
    caseNumber:'',
    performer:'',
    phone:'',
    description:'',
    inspectionDate: '',
    inspectionStartTime: '',
    inspectionEndTime: '',
  })
  const [ singleBuildingData, setSingleBuildingData ] = useState([]);
  const [ showMenuState, setShowMenuState ] = useState(false)
  var [isInfoTabEnabled, setInfoTabVisibility] = useState(true)
  var [isMsgTabEnabled, setMsgTabVisibility] = useState(false)
  var [isDocTabEnabled, setDocTabVisibility] = useState(false)
  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))
  const userRole = userDetails.role.title
  const token = localStorage.getItem('authToken')
  const location = useLocation()
  const data = location.state
  const parameters = useParams()
  const dashboardRef = useRef(0)
  const history = useHistory()

  useEffect(() => {
    isTokenValid(history)
  }, []);

  useEffect(() => {
    let buildingData = {}
    let tempBuildingDetails=buildingDetails

    axios.post('https://backend.production.bip.agiprod.agifly.cloud/user/all_admin', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
        setResponsibleList(response.data.data)
    }).catch(error => {
      console.log(error)
    })
    
    if (parameters.id > 0) {
      axios
        .post(
          userRole === 'Extern' ? 'https://backend.production.bip.agiprod.agifly.cloud/buildlings_external/getById' : `https://backend.production.bip.agiprod.agifly.cloud/buildlings/get_by_id`,
          { id: parameters.id },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          let newData = [];
          if(userRole === 'Extern') {
            newData = response.data[0];
            newData.projects = newData.building.projects;
            delete newData.building;
          }
          setSingleBuildingData(response.data[0])
          if (response.data[0] !== null) {
            buildingData = userRole === "Extern" ? newData : response.data[0]
            tempBuildingDetails.buildingName=buildingData.title
            tempBuildingDetails.buildingAddress=buildingData.address
            tempBuildingDetails.status=serviceStatus
            tempBuildingDetails.fileName=buildingData.fileName
            tempBuildingDetails.shift= buildingData.shift
            tempBuildingDetails.typeOfProperty=buildingData.typeOfProperty
            tempBuildingDetails.caseNumber=serviceFileNumber
            tempBuildingDetails.zip=buildingData.zip
            tempBuildingDetails.city=buildingData.city
            tempBuildingDetails.performer=getResponsibleName(responsibleId)[0]
            tempBuildingDetails.phone= getResponsibleName(responsibleId)[1]
            tempBuildingDetails.company = buildingData.company;
            tempBuildingDetails.description=buildingData.description
            if(buildingData.projects.length>0){
              setProjectId(buildingData.projects[0].id)
              if(buildingData.projects[0].projectServices.length>0){
                setServiceId(buildingData.projects[0].projectServices[0].serviceId)
                setResponsibleId(buildingData.projects[0].projectServices[0].assigned_admin_id)
                setServiceFileNumber(buildingData.projects[0].projectServices[0].fileNumber)
                setServiceStatus(buildingData.projects[0].projectServices[0].service_status_id)
              }
            }
            setBuildingDetails(tempBuildingDetails)
            setBuildingId(buildingData.id)
          }
        })
        .catch((error) => {})
    }
  }, [parameters.id])

  useEffect(() => {
    setCategoryId(categoryId)
  }, [categoryId])

  useEffect(()=>{},[projectId])

  useEffect(()=>{
  },[responsibleId])

  useEffect(()=>{
  },[singleBuildingData])

  useEffect(() => {
    setServiceId(serviceId)
    var tempBuildingDetails={...buildingDetails}
    axios
      .post(
        `https://backend.production.bip.agiprod.agifly.cloud/service/get_by_id`,
        { id:serviceId },
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        tempBuildingDetails.shift=response.data.title
        tempBuildingDetails.status=serviceStatus
        tempBuildingDetails.caseNumber=serviceFileNumber
        let projectServiceData = singleBuildingData.projects[0].projectServices.filter(data => data.id === projectServiceId)
        tempBuildingDetails.inspectionDate = projectServiceData[0]?.billing_date
        tempBuildingDetails.inspectionStartTime = projectServiceData[0]?.billing_start_time
        tempBuildingDetails.inspectionEndTime = projectServiceData[0]?.billing_end_time
        if(userRole === 'Architect/Builder' || userRole === "Extern"){
          tempBuildingDetails.performer=getResponsibleName(responsibleId)[0]
          tempBuildingDetails.phone= getResponsibleName(responsibleId)[1]
        }
        else{
          tempBuildingDetails.performer=getResponsibleName(parseInt(data.assigned_admin_id))[0]
          tempBuildingDetails.phone= getResponsibleName(parseInt(data.assigned_admin_id))[1]
        }
        setBuildingDetails(tempBuildingDetails)
      })
      .catch((error) => {
      })
  }, [serviceId,serviceStatus,responsibleId,serviceFileNumber])
  
  useEffect(() => {
    setProjectServiceId(projectServiceId)
  }, [projectServiceId])

  const updateCategoryId = (id) => {
    setCategoryId(id)
    setUpdate(!update)
  }

  const updateServiceId = (id, servicesMetaTitle) => {
    var tempBuildingDetails={...buildingDetails}
    tempBuildingDetails.typeOfProperty=servicesMetaTitle == null ? null:servicesMetaTitle
    setBuildingDetails(tempBuildingDetails)
  
    setServiceId(id)
  }

  function getResponsibleName(id){
    let phone='-';
    let name='-';
    if(responsibleList.length>0){
      responsibleList.forEach(item=>{
        if(item.id===id){
          name=item.firstName+' '+item.lastName;
          phone=item.phone;
        }
      })
    }
    return [name, phone]
    
  }

  const handleToggleSidebar = () => {
    dashboardRef.current.focus()
    setShowMenuState(!showMenuState)
  }

  const handleTabChange = (value) => {
    console.log(value)
    setInfoTabVisibility(
      value === 'info' ? (isInfoTabEnabled = true) : (isInfoTabEnabled = false),
    )
    setMsgTabVisibility(
      value === 'emails' ? (isMsgTabEnabled = true) : (isMsgTabEnabled = false),
    )
    setDocTabVisibility(
      value === 'documents'
        ? (isDocTabEnabled = true)
        : (isDocTabEnabled = false),
    )
  }

  return (
    <Grid
      xs={12}
      md={12}
      className="residence-grid-height height-auto background-color-greyish-white"
    >
      <div className = "residence-sidebar-style fixed animatable-400 ease-in-out-quart" tabIndex = "1" ref = {dashboardRef} onBlur = {() => { setShowMenuState(false) }} style = {{transform: showMenuState ? 'translateX(0px)' : 'translateX(-260px)', opacity: showMenuState ? 1 : 0}} >
        <DashboardSidebar modular  />
      </div>
      <Grid container>
        <Header toShowHamburger={true} handleToggleSidebar={handleToggleSidebar} />
      </Grid>
      <Grid container justify="center" className='background-color-greyish-white'>
        {userRole === 'Architect/Builder' || userRole === "Extern" ? (
          <>
            <Grid container>
              <Grid container>
                <div className="margin-top-13" />
              </Grid>
              <Grid container  style = {{padding: window.screen.width < 600 ? '0px 15px' : '0px'}}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  justify="center"
                >
                  <Grid container>
                    <ResidenceAmboise
                      sendData={data}
                      buildingData={buildingDetails}
                      projectId={projectId}
                      sendCategoryId={updateCategoryId}
                      token={token}
                      categoryId = {categoryId}
                    />
                  </Grid>
                  <Grid container>
                    <ResidenceAmboiseActions
                      sendData={data}
                      projectId={projectId}
                      categoryId={categoryId}
                      update={update}
                      sendServiceId={updateServiceId}
                      token={token}
                      projectServiceId={projectServiceId}
                      setProjectServiceId={setProjectServiceId}
                      serviceStatus={serviceStatus}
                      setServiceStatus={setServiceStatus}
                      setFileNumber={setServiceFileNumber}
                      setResponsibleId={setResponsibleId}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} justify="center" className = 'animatable-400 fade-in-translate'>
                  {
                    categoryId === 8 ?
                      <PremiumInfo setCategoryId = {setCategoryId} handleChange = {handleTabChange}/>
                    :
                    <ActionsInformation
                      token={token}
                      buildingId={buildingId}
                      serviceId={serviceId}
                      projectId={projectId}
                      projectServiceId={projectServiceId}
                      buildingData={buildingDetails}
                      singleBuildingData = {singleBuildingData}
                      handleChange = {handleTabChange}
                      isInfoTabEnabled = {isInfoTabEnabled} 
                      isMsgTabEnabled = {isMsgTabEnabled} 
                      isDocTabEnabled = {isDocTabEnabled}
                      userRole = {userRole}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container>
            <Grid container>
              <div className="margin-top-13" />
            </Grid>
            <Grid container xs={12} sm={12} md={6} lg={6} justify="center">
              <Hidden smDown>
                <Grid container xs={1} sm={1} md={1} justify="center">
                </Grid>
              </Hidden>
              <Grid container item xs={12} sm={11} md={11}>
                <Grid container xs={6} sm={5} md={5} direction='column'>
                  <Hidden xsDown>
                    <p className="vietnam-bold font-size-18 font-color-black">
                      {buildingDetails.buildingName===''?'Building Name':buildingDetails.buildingName}
                    </p>
                    <p className="vietnam-regular font-size-14 font-color-grey">
                      {buildingDetails.zip === '' ? 'postalcode city' : buildingDetails.zip + " " + buildingDetails.city}
                    </p>
                  </Hidden>
                  <Hidden smUp>
                    <p className="vietnam-bold font-size-18 font-color-black margin-left-15">
                      {buildingDetails.buildingName===''?'Building Name':buildingDetails.buildingName}
                    </p>
                    <p className="vietnam-regular font-size-14 font-color-grey margin-left-15">
                      {buildingDetails.zip === '' ? 'postalcode city' : buildingDetails.zip + " " + buildingDetails.city}
                    </p>
                  </Hidden>
                </Grid>
                
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={11}
                  justify="center"
                  alignItems="flex-start"
                  className="residence-half-grid-height "
                >
                  <InfoTab token={token} buildingId={buildingId} serviceId={data.id} buildingData={buildingDetails} projectServiceData={data} projectId={projectId} userRole={userRole} singleBuildingData = {singleBuildingData} />
                </Grid> 
                <Grid container justify="center">
                  <div className="residenc-amboise-end-line " />
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={11}
                  className="residence-half-grid-height"
                >
                  <DocumentDetails
                    token={token}
                    serviceId={data.serviceId}
                    projectId={projectId}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} sm={12} md={6} lg={6} className="">
              <div className="residence-line-between" />
              <EmailsTab token={token} singleBuildingData = {singleBuildingData} projectServiceId = {data.id} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
